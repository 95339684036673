<template>
  <div>
    <div class="row">
      <div class="col-12 col-sm-6 col-lg-3 mb-3">
        <b>Situação</b><br />
        <b-select
          :options="['Todos', 'Á Emitir', 'Emitidos']"
          v-model="filtros.situacao"
        />
      </div>
      <div class="col-12 col-sm-6 col-lg-5 mb-3">
        <b>Forma Pagamento</b><br />
        <v-select
          multiple
          v-model="filtros.forma_pagamento"
          :options="['PIX', 'Cartão', 'Dinheiro', 'Vale', 'Dim. + Cart.']"
          label="nome"
        />
      </div>
      <div class="col-12 col-lg-4 mb-3">
        <table class="table table-sm" style="font-size: 12px">
          <tr>
            <th>Selecionado</th>
            <td>
              {{ totais.selecionadosQtd }}
              -
              {{ totais.selecionadosValor | currencyMask }}
            </td>
          </tr>
          <tr>
            <th>Selecionado Pendente</th>
            <td>
              {{ totais.selecionadosPendentesQtd }}
              -
              {{ totais.selecionadosPendentesValor | currencyMask }}
            </td>
          </tr>
          <!-- <tr>
            <th>Selecionado Emitido</th>
            <td>
              {{ totais.selecionadosEmitidosQtd }}
              -
              {{ totais.selecionadosEmitidosValor | currencyMask }}
            </td>
          </tr> -->
        </table>
        <b-btn
          variant="success"
          size="lg"
          v-if="totais.selecionadosPendentesQtd > 0"
          @click="emitir"
          block
          ><b-icon-file-binary /> Emitir selecionados</b-btn
        >
      </div>
    </div>
    <hr />
    <div v-if="carregando" class="text-center p-5">
      <b-spinner /><br />
      aguarde...
    </div>
    <div v-if="!carregando">
      <div
        class="bg-light p-3 d-flex justify-content-arround text-center"
        style="font-size: 10px; justify-content: space-evenly"
      >
        <div class="">
          <b>Total</b><br />
          {{ lista.length }} -
          {{
            lista.reduce((ret, vl) => {
              ret += vl.vl_total;
              return ret;
            }, 0) || 0 | currencyMask
          }}
        </div>
        <div class="">
          <b>Á Emitir</b><br />
          {{
            lista.filter((x) => !x.status_fiscal || x.status_fiscal === "Pendente" || x.status_fiscal=="Erro").length
          }}
          -
          {{
            lista
              .filter((x) => !x.status_fiscal || x.status_fiscal === "Pendente" || x.status_fiscal === "Erro")
              .reduce((ret, vl) => {
                ret += vl.vl_total;
                return ret;
              }, 0) || 0 | currencyMask
          }}
        </div>
        <div class="">
          <b>Emitido</b><br />
          {{ lista.filter((x) => x.status_fiscal === "Concluido").length }}
          -
          {{
            lista
              .filter((x) => x.status_fiscal === "Concluido")
              .reduce((ret, vl) => {
                ret += vl.vl_total;
                return ret;
              }, 0) || 0 | currencyMask
          }}
        </div>
      </div>
      <b-table
        :items="lista"
        style="font-size: 10px"
        :fields="[
          { key: 'sel', label: '', class: 'text-center' },
          { key: 'fiscal.numero', label: 'Nº Nota', class: 'text-center' },
          { key: 'cod_pedido', label: 'Cod. Pedido', class: 'text-center' },
          { key: 'empresa', label: 'Loja', class: 'text-center' },
          { key: 'senha', label: 'Senha', class: 'text-center' },
          { key: 'tipo_entrega', label: 'Tipo', class: 'text-center' },
          { key: 'pagamento', label: 'Pagamento', class: 'text-center' },
          { key: 'cliente', label: 'Cliente', class: 'text-center' },
          { key: 'vl_subtotal', label: 'Sub-total', class: 'text-center' },
          { key: 'vl_entrega', label: 'Entrega', class: 'text-center' },
          { key: 'vl_desconto', label: 'Desconto', class: 'text-center' },
          { key: 'taxa', label: 'Taxa%', class: 'text-center' },
          { key: 'vl_total', label: 'Total', class: 'text-center' },
          { key: 'status_fiscal', label: 'Situação', class: 'text-center' },
        ]"
        small
        @row-selected="onRowSelected"
        select-mode="single"
        hover
        selectable
        ref="tbPEDS"
        :current-page="currentPage"
        :per-page="perPage"
      >
        <template #head(sel)>
          <b-checkbox @change="checkAll" />
        </template>
        <template #cell(sel)="row">
          <b-checkbox
            v-model="row.item.sel"
            :checked="true"
            :unchecked-value="false"
            :disabled="row.item.status_fiscal === 'Concluido'"
          />
        </template>
        <template #cell(cliente)="row">
          {{ row.item.cliente.Nome }}
        </template>
        <template #cell(vl_subtotal)="row">
          {{ row.item.vl_subtotal | currency }}
        </template>
        <template #cell(vl_entrega)="row">
          {{ row.item.vl_entrega | currency }}
        </template>
        <template #cell(taxa)="row">
          {{ row.item.taxa | currency }}
        </template>
        <template #cell(vl_total)="row">
          {{ row.item.vl_total | currency }}
        </template>
        <template #cell(status_fiscal)="row">
          <div
            :class="`text-white p-1 ${
              row.item.status_fiscal == 'Pendente' || row.item.status_fiscal == 'Erro'
                ? 'bg-danger'
                : row.item.status_fiscal == 'Enviado'
                ? 'bg-light text-info'
                : 'bg-success'
            }`"
            @click.prevent.stop="openNotaDetails(row.item.cod_pedido)"
          >
            {{ row.item.status_fiscal || "Pendente" }}
          </div>
        </template>
      </b-table>

      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="my-0"
      ></b-pagination>
    </div>
    <b-modal
      id="modal-emitindo"
      hide-footer
      hide-top
      hide-header
      busy
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="p-5 text-center">
        <div v-if="executando">
          <b-spinner
            style="width: 100px; height: 100px; font-size: 2px"
            variant="success"
          />
          <br />

          <h2 class="text-center m-4">
            Executando {{ progress.value }} de {{ progress.max }}
          </h2>
          <small>{{ progress.msg }}</small>
          <b-progress
            :max="progress.max"
            variant="info"
            :value="progress.value"
            animated
          />
          <hr />
          <b-btn variant="danger" class="mt-4" @click="pararExecucao"
            ><b-icon-stop-circle /> Parar execução</b-btn
          >
        </div>
        <div
          v-if="progress.value == progress.max && !executando"
          style="font-size: 40px"
          class="text-success"
        >
          <div style="font-size: 150px !important">
            <b-icon-check-circle-fill />
          </div>
          Concluído!
        </div>
        <hr />
        <small class="text-left">
          <b>Log de execução:</b>
          <hr />
          <ul>
            <li v-for="l in log" :key="`l.id${l.n}`">
              {{ l.log }}
            </li>
          </ul>
        </small>
        <b-btn variant="success" size="lg" @click="pararExecucao" block v-if="!executando"
          ><b-icon-door-closed /> Fechar</b-btn
        >
      </div>
    </b-modal>
    <b-modal id="modal-fiscal" title="Cupom Fiscal" size="xl" hide-footer>
      <CupomFiscal :PedidoID="CupomOpen.cod_pedido" :CodEmpresa="CupomOpen.cod_empresa" />
    </b-modal>
  </div>
</template>

<script>
import { data } from "core-js-pure/internals/is-forced";
import moment from "moment";
import Pedido from "../../libs/Pedido";
import FiscalLib from "../../libs/FiscalLib";
import CupomFiscal from "../../components/Caixas/CupomFiscal.vue";
export default {
  components: { CupomFiscal },
  props: {
    data: Object,
    empresas: Array,
  },
  mounted() {
    this.carregar();
  },
  data() {
    return {
      currentPage: 1,
      totalRows: 1,
      perPage: 50,
      filtros: {
        dt_i: moment(data.start).format("YYYY-MM-DD"),
        dt_f: moment(data.end).format("YYYY-MM-DD"),
        situacao: "Á Emitir",
        forma_pagamento: ["PIX", "Cartão", "Dim. + Cart."],
      },
      carregando: false,
      lista: [],
      totais: {
        selecionadosQtd: 0,
        selecionadosValor: 0,
        selecionadosPendentesQtd: 0,
        selecionadosPendentesValor: 0,
        selecionadosEmitidosQtd: 0,
        selecionadosEmitidosValor: 0,
      },
      executando: false,
      progress: {
        max: 1,
        value: 0,
        msg: "",
      },
      log: [],
      CupomOpen: {
        cod_pedido: 0,
        cod_empresa: 0,
      },
    };
  },
  watch: {
    $props: {
      deep: true,
      handler() {
        this.filtros.dt_i = moment(this.data.start).format("YYYY-MM-DD");
        this.filtros.dt_f = moment(this.data.end).format("YYYY-MM-DD");
        this.carregar();
      },
    },
    "filtros.dt_i": {
      deep: true,
      handler() {
        this.carregar();
      },
    },
    "filtros.dt_f": {
      deep: true,
      handler() {
        this.carregar();
      },
    },
    "filtros.forma_pagamento": {
      deep: true,
      handler() {
        this.filtrar();
      },
    },
    "filtros.situacao": {
      deep: true,
      handler() {
        this.filtrar();
      },
    },
    lista: {
      deep: true,
      handler() {
        console.log("lista", this.lista);
        this.calcular();
      },
    },
    progress: {
      deep: true,
      handler() {
        if (this.progress.msg != "") {
          this.log.push({
            n: this.log.length,
            log: this.progress.msg,
          });
        }
      },
    },
  },
  computed: {},
  methods: {
    openNotaDetails(id) {
      this.CupomOpen.cod_pedido = id;
      let fnd = this.lista.find((x) => x.cod_pedido == id);
      console.log("fnd", fnd);
      if (fnd) {
        this.CupomOpen.cod_empresa = fnd.cod_empresa;
      }
      this.$bvModal.show("modal-fiscal");
    },
    pararExecucao() {
      this.executando = false;
      this.$nextTick();
      this.$bvModal.hide("modal-emitindo");
      setTimeout(async () => {
        await this.carregar(true);
      }, 200);
    },
    async emitir() {
      let listaExec = this.lista.filter((x) => x.sel === true);

      if (listaExec.length <= 0) {
        this.$swal("Atenção", "Selecione ao menos 1 item para emitir!", "error");
        return;
      }

      let continua = await this.$swal({
        title: "Deseja continuar?",
        text:
          "Tem certeza que deseja emitir nota para os " +
          listaExec.length +
          " pedidos selecionados?",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
      }).then((x) => x.isConfirmed);

      if (!continua) {
        return;
      }
      this.log = [];
      this.$bvModal.show("modal-emitindo");
      this.progress.msg = "Iniciando execução...";
      this.executando = true;
      this.progress.max = listaExec.length;
      this.progress.value = 0;
      this.$nextTick();
      for (let nota of listaExec) {
        this.$nextTick();
        this.progress.value++;
        this.$nextTick();

        if (this.executando) {
          console.log("start notta ", nota.cod_pedido);
          this.progress.msg =
            "Pedido " +
            nota.cod_pedido +
            " | Senha " +
            nota.senha +
            " | Status: Enviando...";
          this.$nextTick();
          await new Promise((resolve) => setTimeout(resolve, 2000));
          await this.$nextTick(async () => {
            if (continua) {
              let res = await FiscalLib.emitirNotaLocal(nota.cod_pedido, nota.cliente);
              this.progress.msg = this.progress.msg.replace(
                "Enviando...",
                res && res.success === true ? "Concluido!" : res.message
              );
              this.$nextTick();
            }
          });
        }
        //this.progress.value++;
        this.$nextTick();
      }
      await this.$nextTick();

      setTimeout(() => {
        this.executando = false;
        this.progress.value = this.progress.max;
        this.progress.msg = "Finalizado!";
      }, 2000);
    },
    calcular() {
      this.totais.selecionadosQtd = this.lista.filter((x) => x.sel === true).length;
      this.totais.selecionadosValor = this.lista
        .filter((x) => x.sel === true)
        .reduce((ret, vl) => {
          ret += vl.vl_total;
          return ret;
        }, 0);

      this.totais.selecionadosPendentesQtd = this.lista.filter(
        (x) => x.sel === true && (!x.status_fiscal || x.status_fiscal == "Pendente" || x.status_fiscal == "Erro")
      ).length;
      this.totais.selecionadosPendentesValor = this.lista
        .filter(
          (x) => x.sel === true && (!x.status_fiscal || x.status_fiscal == "Pendente"|| x.status_fiscal == "Erro")
        )
        .reduce((ret, vl) => {
          ret += vl.vl_total;
          return ret;
        }, 0);

      this.totais.selecionadosEmitidosQtd = this.lista.filter(
        (x) => x.sel === true && x.status_fiscal == "Concluido"
      ).length;
      this.totais.selecionadosEmitidosValor = this.lista
        .filter((x) => x.sel === true && x.status_fiscal == "Concluido")
        .reduce((ret, vl) => {
          ret += vl.vl_total;
          return ret;
        }, 0);
      this.$forceUpdate();
    },
    checkAll(ev) {
      console.log(ev);
      this.lista.map((it) => {
        if (it.status_fiscal == "Pendente" || it.status_fiscal == "Erro") it.sel = ev;
      });
      this.$refs.tbPEDS.refresh();
      this.calcular();
    },
    onRowSelected(itens) {
      itens.forEach((it) => {
        if (it.status_fiscal == "Pendente" || it.status_fiscal == "Erro") it.sel = it.sel ? false : true;
      });
      this.$refs.tbPEDS.clearSelected();
      this.calcular();
    },
    async carregar(forceAwait = false) {
      this.carregando = true;
      this.lista = [];
      if (forceAwait) {
        this.$forceUpdate();
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, 3200);
        });
      }
      try {
        console.log({
          data: this.filtros.dt_i,
          dataAte: this.filtros.dt_f,
          empresas: this.empresas.map((r) => r.cod_empresa),
          withFiscal: true,
        });
        this.listaRaw = (
          await Pedido.get({
            data: this.filtros.dt_i,
            dataAte: this.filtros.dt_f,
            empresas: this.empresas.map((r) => r.cod_empresa),
            withFiscal: true,
            cod_empresa: -1,
          })
        ).data;

        this.currentPage = 1;
        this.totalRows = this.lista.length;
        // this.lista = this.lista.map(li => ({
        //   ...li,
        //   _rowVariant:li.status_fiscal!=='Pendente'?'secondary':''
        // }))
        this.filtrar();
        this.$forceUpdate();
        console.log("lista", this.lista);
      } catch (err) {
        console.log("Erro ao carregar", err);
      }
      this.carregando = false;
    },
    async filtrar() {
      this.lista = this.listaRaw;
      if (this.filtros.forma_pagamento && this.filtros.forma_pagamento.length > 0) {
        this.lista = this.lista.filter((x) =>
          this.filtros.forma_pagamento.includes(x.pagamento)
        );
      }
      if (this.filtros.situacao != "Todos") {
        this.lista = this.lista.filter((x) =>
          this.filtros.situacao == "Á Emitir"
            ? ["Pendente", "Enviado", "Erro"].includes(x.status_fiscal)
            : x.status_fiscal == "Concluido"
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
